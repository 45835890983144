import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import _ from "lodash";
import axios from "axios";
import {
  ICard,
  IPlayerTurn,
  IHand,
  IPlayResponse,
  IPlayRequest,
  IChat,
  IProfile,
  IDisk,
} from "../../Types/types";
import Game from "../Game";

type ShellProps = {
  token: string;
  gameId: string;
  playerEmail: string;
  turn: IPlayerTurn;
  chat: IChat | undefined;
  profile: IProfile | undefined;
  reset: boolean;
  fetchGames: () => void;
  refreshGame: () => void;
  hardRefreshGame: () => void;
  nextGame: () => void;
  logout: () => void;
  sendChat: (message: string) => void;
};

const Shell: React.FC<ShellProps> = ({
  token,
  gameId,
  playerEmail,
  turn,
  chat,
  profile,
  reset,
  fetchGames,
  refreshGame,
  hardRefreshGame,
  nextGame,
  logout,
  sendChat,
}) => {
  const [player, setPlayer] = useState(turn.player);
  const [turnId, setTurnId] = useState(turn.turnId);
  const [isMyTurn, setIsMyTurn] = useState<boolean>(turn.isPlayersTurn);
  const [isWinner, setIsWinner] = useState<boolean | undefined>(turn.isWinner);
  const [isGameOver, setIsGameOver] = useState<boolean>(turn.isGameOver);
  const [history, setHistory] = useState<IDisk[][]>(turn.history);
  const [stackSize, setStackSize] = useState<number>(turn.stackSize);
  const [rows, setRows] = useState<number>(turn.rows);
  const [cols, setCols] = useState<number>(turn.cols);
  const [waitingOn, setWaitingOn] = useState<string | undefined>(
    turn.waitingOn
  );
  const [prompt, setPrompt] = useState<string>(
    turn.prompt
  );

  useEffect(() => {
    setPlayer(turn.player);
    setIsWinner(turn.isWinner)
    setHistory(turn.history);
    setTurnId(turn.turnId);
    setStackSize(turn.stackSize);
    setRows(turn.rows);
    setCols(turn.cols);
    setIsMyTurn(turn.isPlayersTurn);
    setWaitingOn(turn.waitingOn);
    setIsGameOver(turn.isGameOver);
    setPrompt(turn.prompt);
  }, [reset]);

  useEffect(() => {
    setPlayer(turn.player);
    setIsWinner(turn.isWinner)
    setHistory(turn.history);
    setTurnId(turn.turnId);
    setStackSize(turn.stackSize);
    setRows(turn.rows);
    setCols(turn.cols);
    setIsMyTurn(turn.isPlayersTurn);
    setWaitingOn(turn.waitingOn);
    setIsGameOver(turn.isGameOver);
    setPrompt(turn.prompt);
  }, [turn]);

  const play = (play: IPlayRequest) => {
    const url = "/" + gameId + "/play";
    axios
      .post<IPlayerTurn>(
        url,
        play,
        {
          headers: {
            "x-access-token": token,
            "x-player-email": playerEmail,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const pt = res.data as IPlayerTurn;
          console.log("play response", pt.history);
          // setPlayer(pt.player);
          // setIsWinner(pt.isWinner)
          // setHistory(pt.history);
          // setTurnId(pt.turnId);
          // setStackSize(pt.stackSize);
          // setIsMyTurn(pt.isPlayersTurn);
          // setWaitingOn(pt.waitingOn);
          // setIsGameOver(pt.isGameOver);
          // setPrompt(pt.prompt);
          refreshGame();
          fetchGames();
        } else if (res.status === 401) {
          console.error(res.data.toString());
          logout();
        } else {
          console.error(res.data.toString());
        }
      }).catch(err => {
        console.error(err.toString());
      });
  };


  const confirmGame = (player: string) => {
    const url = "/" + gameId + "/confirmGame";
    axios
      .post<ICard>(
        url,
        {
          player,
        },
        {
          headers: {
            "x-access-token": token,
            "x-player-email": player,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          refreshGame();
        } else {
          console.error(res.data.toString());
          logout();
        }
      }).catch(err => {
        console.error(err.toString());
        logout();
      });
  };

  const doConfirmGame = () => {
    confirmGame(playerEmail);
  };

  return (
    <Grid
      container
      style={{
        width: "100%",
        border: "0px solid #0f1919",
        background: "transparent",
      }}
    >
      <Grid item xs={12}>
        <Game
          turnId={turnId}
          player={player}
          gameId={gameId}
          isGameOver={isGameOver}
          isMyTurn={isMyTurn}
          isWinner={isWinner}
          history={history}
          stackSize={stackSize}
          rows={rows}
          cols={cols}
          waitingOn={waitingOn}
          prompt={prompt}
          reset={reset}
          play={play}
          confirmGame={confirmGame}
          nextGame={nextGame}
          refreshGame={refreshGame}
          hardRefreshGame={hardRefreshGame}
        />
      </Grid>

    </Grid>
  );
};

export default Shell;
