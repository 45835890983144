import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { IPlayerRoundStat, IPlayerStats } from "../../Types/types";
import _ from "lodash";
import GameStatsRow from "../GameStatsRow";

export type StatsProps = {
  stats: IPlayerStats;
};
export const Stats: React.FC<StatsProps> = ({
  stats,
}: StatsProps) => {

  return (
    <Grid
      container
      item
      xs={12}
    >
      <Grid display="flex" justifyContent="center" container item xs={12} style={{ marginBottom: '8px' }} >
        <Grid display="flex" justifyContent="center" container item xs={3}>

        </Grid>
        <Grid display="flex" justifyContent="center" container item xs={3}>
          <Typography
            align="center"
            variant="h2"
            style={{
              width: "100%",
              color: "#0f1919",
              margin: "4px",
            }}
          >
            wins
          </Typography>
        </Grid>
        <Grid display="flex" justifyContent="center" container item xs={3}>
          <Typography
            align="center"
            variant="h2"
            style={{
              color: "purple",
              width: "100%",
              margin: "4px",
            }}
          >
            longbones
          </Typography>
        </Grid>
        <Grid display="flex" justifyContent="center" container item xs={3}>
          <Typography
            align="center"
            variant="h2"
            style={{
              color: "darkorange",
              width: "100%",
              margin: "4px",
            }}
          >
            multibones
          </Typography>
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="center" container item xs={12} style={{ marginBottom: '8px' }} >
        <Grid display="flex" justifyContent="center" container item xs={3}>
          <Typography
            align="center"
            variant="h2"
            style={{
              width: "100%",
              color: "#0f1919",
              margin: "0px",
            }}
          >
            game
          </Typography>
        </Grid>
        <Grid display="flex" justifyContent="center" container item xs={9} style={{ marginTop: "4px", width: "100%" }}>
          <GameStatsRow wins={stats.gameStats.wins} losses={stats.gameStats.losses} lbs={stats.gameStats.lbs} mbs={stats.gameStats.mbs} />
        </Grid>
      </Grid>



      <Grid container item justifyContent="center">
        {stats.roundStats.map((roundStat: IPlayerRoundStat, i) => (

          <Grid display="flex" justifyContent="center" key={"k-prs" + i} container item xs={12} style={{ marginBottom: '8px' }} >
            <Grid display="flex" justifyContent="center" container item xs={3}>
              <Typography
                align="center"
                variant="h2"
                style={{
                  width: "100%",
                  color: "#0f1919",
                  margin: "0px",
                }}
              >
                {roundStat.roundNumber}-zies
              </Typography>
            </Grid>
            <Grid display="flex" justifyContent="center" container item xs={9} style={{ marginTop: "0px", width: "100%" }}>
              {/* <RoundStatsRow title={`${roundStat.roundNumber}-zies win rate`} wins={roundStat.wins} losses={roundStat.losses} lbs={roundStat.lbs} mbs={roundStat.mbs} /> */}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Stats;
