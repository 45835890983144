import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import useMousePosition from "../../hooks/useMousePosition";

import {
  IDisk,
  IPlayRequest,
} from "../../Types/types";
import _ from "lodash";
import { useInterval } from "usehooks-ts";


type GameProps = {
  turnId: string;
  player: string;
  gameId: string;
  isGameOver: boolean;
  isMyTurn: boolean;
  isWinner: boolean | undefined;
  history: IDisk[][];
  stackSize: number;
  rows: number;
  cols: number;
  waitingOn: string | undefined;
  prompt: string;
  reset: boolean;
  play: (play: IPlayRequest) => void;
  confirmGame: (player: string) => void;
  nextGame: () => void;
  refreshGame: () => void;
  hardRefreshGame: () => void;
};

const Game: React.FC<GameProps> = ({
  turnId,
  player,
  gameId,
  isGameOver,
  isMyTurn,
  isWinner,
  history,
  stackSize,
  rows,
  cols,
  waitingOn,
  prompt,
  reset,
  play,
  confirmGame,
  nextGame,
  refreshGame,
  hardRefreshGame,

}) => {
  const confirmPlay = (turn: IPlayRequest) => {
    setInfoMessage("");
    play(turn);
  };

  const [disks, setDisks] = useState<IDisk[]>([]);
  const [infoMessage, setInfoMessage] = useState<string | undefined>(undefined);

  const [frames, setFrames] = useState<number>(history.length);
  const [index, setIndex] = useState<number>(0);
  const [animate, setAnimate] = useState<boolean>(history.length > 1);
  const [animateRequest, setAnimateRequest] = useState<boolean>(true);
  const [warning, setWarning] = useState<boolean>(false);

  useInterval(() => {
    if (animate && animateRequest) {
      if (index < frames) {
        setDisks(history[index]);
        setIndex(index + 1);
      } else {
        setIndex(0);
        setAnimate(false);
        setAnimateRequest(false);
      }
    }
  }, animate ? 100 : null
  );

  useEffect(() => {
    setInfoMessage("");
  }, [reset]);

  useEffect(() => { }, [infoMessage]);

  useEffect(() => {
    setInfoMessage(prompt);
  }, [prompt]);

  useEffect(() => {
    console.log("history", history.length);
    //setAnimate(history.length > 1);
    //setDisks(history[history.length - 1]);
    setDisks(history[history.length - 1]);
    setFrames(history.length);
    setIndex(0);
  }, [history]);

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [coords, handleCoords] = useMousePosition(true);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, rows, cols);
        ctx.fillStyle = "#e8eddf";
        ctx.fillRect(0, 0, rows, cols);
      }
    }
    (disks || []).forEach((disk) => {
      if (canvasRef.current) {
        const ctx = canvasRef.current.getContext("2d");
        if (ctx) {
          ctx.beginPath();
          ctx.arc(disk.point.x, disk.point.y, 20, 0, 2 * Math.PI);
          ctx.fillStyle = "#f5cb5c";
          ctx.fill();
          ctx.stroke();

          // ctx.beginPath();
          // ctx.arc(disk.point.x, disk.point.y, 80, 0, 2 * Math.PI);
          // ctx.stroke();

          // ctx.fillStyle = "black";
          // ctx.font = "28px serif";
          // ctx.fillText(disk.id.toString(), disk.point.x - 8, disk.point.y + 8,);
        }
      }
    })
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        ctx.fillStyle = "#242423";
        ctx.strokeRect(0, 0, rows, cols);
      }
    }
  }, [disks]);

  const accent = (x: number, y: number) => {
    if (x <= y) return '#f5cb5c';
    return 'transparent';
  };

  const borderAccent = (x: number, y: number) => {
    if (x <= y) return '1.5px solid #0f1919';
    return '0px solid #0f1919';
  };

  return (
    <Grid
      container
      style={{
        border: "0px solid #0f1919",
        background: "#fafafa",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid container item
        justifyContent={"center"}
        xs={12} >
        <Button
          style={{
            background: "green",
            width: "90%",
            height: "38.5px",
            color: "white",
            border: "1px solid #0f1919",
            padding: "8px",
            marginTop: "24px",
          }}

          onClick={(e) => {
            refreshGame();
            setAnimateRequest(false);
          }}

        >
          {animate ? index : "Replay"}
        </Button>
      </Grid>
      <Grid container item
        justifyContent={"center"}
        xs={12} >
        <Typography variant="h1" style={{ color: "#0f1919" }}>  {isGameOver ? "GAME OVER - you " + (isWinner ? "WIN" : "LOSE") : ""} </Typography>
      </Grid>

      <Grid container item
        justifyContent={"center"}
        xs={12} >
        <Typography variant="h1" style={{ color: "#0f1919" }}>  {isMyTurn ? "YOUR turn" : "waiting on " + waitingOn} </Typography>
      </Grid>

      <Grid container item
        justifyContent={"center"}
        xs={12} >
        <Typography variant="h1" style={{ color: "#0f1919" }}>{prompt} </Typography>
      </Grid>

      {
        warning && (
          <Grid container item
            justifyContent={"center"}
            style={{ color: "#fca311" }}
            xs={12} >
            <Typography variant="h1" style={{ color: "#14213d" }}>PRESS AGAIN </Typography>
          </Grid>
        )
      }
      <canvas
        ref={canvasRef}
        height="400"
        width="400"
        style={{ border: "2px solid black" }}
        id="canvas-ele1"
        onClick={(e) => {
          const isInvalid = (coords.x === 0) || (coords.y === 0) || (coords.x === cols) || (coords.y === rows)
          setWarning(isInvalid);
          console.log("coords", isMyTurn, coords.x, coords.y);
          if (isMyTurn && !isInvalid) {
            console.log("coords2", isMyTurn, isInvalid);
            play({ turnId: turnId, guess: { x: coords.x, y: coords.y } });
          }
          // handleCoords((e as unknown) as MouseEvent);
          // if (canvasRef.current) {
          //   const ctx = canvasRef.current.getContext("2d");
          //   ctx?.strokeRect(coords.x, coords.y, 40, 50);
          // }
        }}>

      </canvas>

      <Grid container item xs={12} style={{ width: "10%", maxHeight: "70px", minHeight: "70px" }}>
        <Grid
          justifyContent={"center"}
          container item style={{ width: "30px", }}>
          <Typography variant="h1" style={{ color: "#0f1919" }}>{stackSize} </Typography>
        </Grid>
        <Grid container item style={{ width: "30px", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(1, stackSize), background: accent(1, stackSize) }}>  </Grid>
        </Grid>
        <Grid container item style={{ width: "30px", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(2, stackSize), background: accent(2, stackSize) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "30px", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(3, stackSize), background: accent(3, stackSize) }}>  </Grid>
        </Grid>
        <Grid container item style={{ width: "30px", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(4, stackSize), background: accent(4, stackSize) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "30px", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(5, stackSize), background: accent(5, stackSize) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "30px", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(6, stackSize), background: accent(6, stackSize) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "30px", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(7, stackSize), background: accent(7, stackSize) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "30px", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(8, stackSize), background: accent(8, stackSize) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "30px", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(9, stackSize), background: accent(9, stackSize) }}> </Grid>
        </Grid>
        <Grid container item style={{ width: "30px", }}>
          <Grid item style={{ margin: '1px', width: '100px', border: borderAccent(10, stackSize), background: accent(10, stackSize) }}> </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        style={{
          justifyContent: "center",
          marginTop: "8px",
          marginBottom: "8px",
          marginLeft: "12px",
          marginRight: "12px",
        }}
      >
        <Button
          style={{
            background: "#d90429",
            width: "100%",
            //  height: "40px",
            textTransform: "none",
            alignItems: "center",
            border: "1px solid #0f1919",
          }}
          onClick={(e) => hardRefreshGame()}
        >
          <Grid
            container
            item
            xs={12}
            style={{
              justifyContent: "center",
              // marginTop: "4px",
              // marginBottom: "4px",
            }}
          >
            <Typography
              align="center"
              variant="h5"
              style={{
                color: "#edf2f4",
                // paddingTop: "8px",
                // paddingRight: "4px",
              }}
            >
              hmmm! something's 'funny with this game
            </Typography>
          </Grid>
        </Button>
      </Grid>
    </Grid >
  );
};

export default Game;


