import React from "react";
import { ICard, IGuess, ILobby, ILobbyGame } from "../../Types/types";
import _ from "lodash";
import LobbyGame from "../LobbyGame";
import { Button, Grid, TextField, IconButton, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from '@mui/icons-material/Add';

export type LobbyGamesProps = {
  lobby: ILobby;
  selectGame: (session: string) => void;
  createGame: () => void;
};
export const LobbyGames: React.FC<LobbyGamesProps> = ({
  lobby,
  selectGame,
  createGame,
}: LobbyGamesProps) => {
  return (
    <Grid
      container item
      display="flex"
      justifyContent="center"
      style={{
        width: "100%",
        paddingTop: "4px",
        paddingBottom: "4px",
        // minHeight: "55px",
        background: "transparent",
        border: "0px solid #0f1919",
      }}
    > <Grid container item xs={12} justifyContent="center">
        <Typography
          align="center"
          variant="h1"
          style={{
            color: "#0f1919",
            marginBottom: "12px",
          }}
        >
          current games
        </Typography>
      </Grid>

      <Grid container item xs={12} justifyContent="center">
        {lobby.games && lobby.games.length > 0 && (
          <Grid container item justifyContent="center">
            {lobby.games.map((game: ILobbyGame, i) => (
              <Grid
                key={"k-gugu" + i}
                style={{
                  background: "transparent",
                  border: "0px solid #0f1919",
                  padding: "0px",
                  margin: "4px",
                }}
              >
                <LobbyGame game={game} selectGame={selectGame} />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      <Grid container item xs={12} justifyContent="center">
        {lobby.games && lobby.games.length === 0 && (
          <Typography
            align="center"
            variant="h2"
            style={{
              color: "#0f1919",
              marginTop: "16px",
              marginBottom: "16px",
            }}
          >
            no current games
          </Typography>
        )}
      </Grid>

      <Grid
        container
        item
        xs={12}
        style={{
          marginTop: "0px",
          marginBottom: "4px",
        }}
      >
        {/* <Grid container item xs={12} >
          <Typography
            variant="h2"
            style={{
              color: "#0f1919",
              margin: "4px",
              marginBottom: "12px",
            }}
          >
            tutorial
          </Typography>
        </Grid> */}
        <Grid container item
          justifyContent={"center"}
          xs={12} >
          <Button
            style={{
              background: "green",
              width: "90%",
              height: "38.5px",
              color: "white",
              border: "1px solid #0f1919",
              padding: "8px",
              marginTop: "24px",
            }}

            onClick={createGame}

          >
            new game
          </Button>
        </Grid>
      </Grid>

      {/* <Grid
        container
        item
        justifyContent={"center"}
        xs={12}
        style={{
          marginTop: "24px",
          marginBottom: "0px",
        }}
      >
        <Typography
          align="center"
          variant="h1"
          style={{
            color: "#171C21",
            paddingLeft: "0px",
            paddingRight: "0px",
            margin: "4px",
          }}
        >
          new game
        </Typography>
      </Grid>

      <Grid
        container
        item
        justifyContent={"center"}
        xs={12}
        style={{
          marginTop: "4px",
          marginBottom: "4px",
        }}
      >
        <IconButton
          style={{
            background: "lightgreen",
            color: "black",
            width: "36px",
            height: "36px",
            border: "1px solid #0f1919",
            margin: "8px",
          }}
          onClick={createGame}
        >
          <AddIcon />
        </IconButton>
      </Grid> */}

    </Grid>
  );
};

export default LobbyGames;
