import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export const JoinGame: React.FC = () => {
  const [token, setToken] = useState<string | undefined>(
    window.localStorage.getItem("token") || undefined
  );
  const [player, setPlayer] = useState<string | undefined>(
    window.localStorage.getItem("player") || undefined
  );
  const { gameId } = useParams();
  const navigate = useNavigate();
  if (token && player && gameId) {
    const url = "/" + gameId + "/join";
    axios
      .post(url, undefined, {
        headers: {
          "x-access-token": token,
          "x-player-email": player,

        },
      })
      .then((res) => {
        if (res.status === 200) {
          window.localStorage.setItem("session", gameId);
          navigate("/");
        } else {
          window.localStorage.removeItem("token");
          setToken(undefined);

          window.localStorage.removeItem("session");
          console.log(res.data as string);
          navigate("/");

        }
      }).catch(err => {
        window.localStorage.removeItem("token");
        setToken(undefined);

        window.localStorage.removeItem("session");
        console.log(err);
        navigate("/");
      });
  }

  return <div />;
};

export default JoinGame;
