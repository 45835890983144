import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ErrorPage: React.FC = () => {
  //const navigate = useNavigate();
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      style={{
        background: "transparent",
        border: "0px solid #0f1919",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          component="img"
          sx={{
            height: 233,
            maxHeight: { xs: 233, md: 167 },
          }}
          alt="Gupp.ie logo"
          src={require("../../images/logo512.png")} 
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          width: "100%",
          height: "100%",
        }}
      >
        {/* <Typography align="center" variant="h1">
          Broken Bonage!
        </Typography> */}
        <Button
          style={{
            background: "#1223de",
            color: "white",
            width: "250px",
            height: "38.5px",
            border: "1px solid #0f1919",
            padding: "7px",
            marginTop: "15px",
          }}
          onClick={(e) => {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("player");
            window.location.href =
              process.env.REACT_APP_URL || "http://www.gupp.ie";
          }}
        >
          Broken Guppie
        </Button>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
