import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Grid, IconButton, Typography } from "@mui/material";
import Shell from "./../Shell";
import Lobby from "./../Lobby";
import SignIn from "./../SignIn";
import Chat from "../Chat";
import {
  IPlayerTurn,
  ICard,
  IChat,
  IFriend,
  IFriends,
  IGameSetup,
  ILobby,
  ILoginResponse,
  IProfile,
  IProfileSaveRequest,
} from "../../Types/types";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import CancelIcon from '@mui/icons-material/Cancel';
import HomeIcon from '@mui/icons-material/Home';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PersonIcon from '@mui/icons-material/Person';
import HowToPlay from "../HowToPlay";
import NewGame from "../NewGame";
import Profile from "../Profile";
import Tutorial from "../Tutorial";
import { useInterval } from "usehooks-ts";

const Disc: React.FC = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState<boolean>(false);
  const [showAdmin, setShowAdmin] = useState<boolean>(false);
  const [turn, setTurn] = useState<IPlayerTurn | undefined>(undefined);
  const [players, setPlayers] = useState<string[]>([]);
  const [chat, setChat] = useState<IChat | undefined>(undefined);
  const [profile, setProfile] = useState<IProfile | undefined>(undefined);
  const [goToNextFlag, setGoToNextFlag] = useState<boolean>(false);

  const [gameId, setGameId] = useState<string | undefined>(undefined);
  const [lobby, setLobby] = useState<ILobby | undefined>(undefined);
  const [friends, setFriends] = useState<IFriends | undefined>(undefined);
  const [listGamesDialog, setListGamesDialog] = useState<boolean>(!gameId);
  const [newGameDialog, setNewGameDialog] = useState<boolean>(false);
  const [howToPlay, setHowToPlay] = useState<boolean>(false);
  const [showTutorial, setShowTutorial] = useState<boolean>(false);
  const [showProfile, setShowProfile] = useState<boolean>(false);

  const [token, setToken] = useState<string | undefined>(
    window.localStorage.getItem("token") || undefined
  );
  const [player, setPlayer] = useState<string | undefined>(
    window.localStorage.getItem("player") || undefined
  );
  const [playerName, setPlayerName] = useState<string | undefined>(undefined);

  const refreshGame = useCallback(() => {
    if (gameId) fetchTurn(gameId)
  }, [gameId]);


  const logout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("player");
    setToken(undefined);
    setPlayer(undefined);
    setPlayerName(undefined);
    setTurn(undefined);
    setGameId(undefined);
    setChat(undefined);
    setLobby(undefined);
    setFriends(undefined);
    setListGamesDialog(true);
    setNewGameDialog(false);
    setShowAdmin(false);
    setShowProfile(false);
    setHowToPlay(false);
    setShowTutorial(false);
    setGoToNextFlag(false);
    setPlayers([]);
    navigate("/");
  };

  const resetLocalStorage = () => {
    logout();
  };

  const login = (email: string) => {
    const url = "/login";
    axios
      .post<ILoginResponse>(url, {
        email: email,
      })
      .then((res) => {
        if ((res.status = 200)) {
          const lres = res.data as ILoginResponse;
          window.localStorage.setItem("token", lres.token);
          window.localStorage.setItem("player", email.toLowerCase());
          setToken(lres.token);
          setPlayer(email);
          setPlayerName(lres.name);
        } else {
          console.error(res.data.toString());
          logout();
        }
      }).catch(err => {
        console.error(err.toString());
        logout();
      });
  };

  const resetGame = () => {
    if (token && gameId && player) {
      const url = "/" + gameId + "/reset";
      axios
        .post<IPlayerTurn>(url, undefined, {
          headers: {
            "x-access-token": token,
            "x-player-email": player,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            logout();
            setGameId(undefined);
            setChat(undefined);
            setTurn(undefined);
            setPlayers([]);
            setToggle(!toggle);
          } else {
            console.error(res.data.toString());
            logout();
          }
        }).catch(err => {
          console.error(err.toString());
          logout();
        });
    }
  };

  const hardRefreshGame = () => {
    console.log("Hard reset game");
    if (token && gameId && player) {
      const url = "/" + gameId + "/refreshTurns";
      axios
        .post<IPlayerTurn>(url, undefined, {
          headers: {
            "x-access-token": token,
            "x-player-email": player,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (gameId) fetchTurn(gameId)
          } else {
            console.error(res.data.toString());
            logout();
          }
        }).catch(err => {
          console.error(err.toString());
          logout();
        });
    }
  };

  const deleteGame = () => {
    if (token && gameId && player) {
      const url = "/" + gameId + "/deleteSession";
      axios
        .post<IPlayerTurn>(url, undefined, {
          headers: {
            "x-access-token": token,
            "x-player-email": player,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            logout();
            setGameId(undefined);
            setChat(undefined);
            setTurn(undefined);
            setPlayers([]);
            setToggle(!toggle);
          } else {
            console.error(res.data.toString());
            logout();
          }
        }).catch(err => {
          console.error(err.toString());
          logout();
        });
    }
  };

  const fetchGames = useCallback(() => {
    if (token) {
      const url = "/fetchGames";

      axios
        .get<ILobby | undefined>(url, {
          headers: {
            "x-access-token": token,
            "x-player-email": player,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const l = res.data as ILobby;
            // if (lobby && lobby.games.length > 0 && !gameId) { setListGamesDialog(true); }
            // if (lobby && lobby.games.length === 0) { setListGamesDialog(true); }
            if (l) setLobby(l);
          } else if (res.status === 401) {
            logout();
          } else {
            console.error(res.data?.toString());
            //logout();
          }
        }).catch(err => {
          console.error(err.toString());
          //logout();
        }).catch(err => {
          console.error(err.toString());
          //logout();
        });
    }
  }, [player, token]);

  const fetchFriends = () => {
    if (token) {
      const url = "/fetchFriends";

      axios
        .get<IFriends | undefined>(url, {
          headers: {
            "x-access-token": token,
            "x-player-email": player,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const mates = res.data as IFriends;
            if (mates) setFriends(mates);
          } else {
            console.error(res.data?.toString());
            logout();
          }
        }).catch(err => {
          console.error(err.toString());
          logout();
        });
    }
  };

  const createGame = () => {
    const setup: IGameSetup = { playerIds: friends?.friends.filter(x => x.isSelected).map(x => x.identity) || [] };
    if (token && player) {
      const url = "/createGame";
      axios
        .post<IPlayerTurn>(url, setup, {
          headers: {
            "x-access-token": token,
            "x-player-email": player,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const g = res.data as IPlayerTurn | undefined;
            setListGamesDialog(false);
            setNewGameDialog(false);
            setNewGameDialog(false)
            setHowToPlay(false);
            setShowProfile(false);
            setGameId(g?.gameId || '');
            setChat(undefined);
            setTurn(g);
            const resetFriends = friends?.friends.map((f: IFriend) => {
              return {
                ...f,
                isSelected: false,
              };
            }
            ) || [];
            setFriends({ friends: resetFriends });
          } else {
            console.error(res.data.toString());
            logout();
          }
        }).catch(err => {
          console.error(err.toString());
          logout();
        });
    } else {
      console.log("No token");
    }
  };
  const fetchTurn = (gameId: string) => {
    if (token && gameId && player) {
      console.log("fetchTurn", gameId);
      const url = "/" + gameId + "/";
      axios
        .get<IPlayerTurn>(url, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
            "x-player-email": player,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const g = res.data as IPlayerTurn | undefined;
            setTurn(g);
          } else {
            console.error(res.data.toString());
            //  logout();
          }
        }).catch(err => {
          console.error(err.toString());
          // logout();
        });
    }
  }


  const fetchGameChat = () => {
    const token = window.localStorage.getItem("token");
    if (token && gameId && player) {
      const url = "/" + gameId + "/chat";
      // axios
      //   .get<IChat>(url, {
      //     headers: {
      //       "x-access-token": token,
      //       "x-player-email": player,
      //     },
      //   })
      //   .then((res) => {
      //     if (res.status === 200) {
      //       const ch = res.data as IChat;
      //       if (ch) setChat(ch);
      //     } else if (res.status === 401) {
      //       console.error(res.data.toString());
      //       logout();
      //     } else {
      //       console.error(res.data.toString());
      //       //logout();
      //     }
      //   }).catch(err => {
      //     console.error(err.toString());
      //     //logout();
      //   });
    } else {
      console.log("No token");
    }
  };
  const sendChat = (message: string) => {
    const token = window.localStorage.getItem("token");
    if (token && player) {
      const url = "/" + gameId + "/chat";
      axios
        .post<IChat>(url, { meesage: message }, {
          headers: {
            "x-access-token": token,
            "x-player-email": player,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const ch = res.data as IChat;
            if (ch) setChat(ch);
          } else {
            console.error(res.data.toString());
            logout();
          }
        }).catch(err => {
          console.error(err.toString());
          logout();
        });
    } else {
      console.log("No token");
    }
  };

  const getProfile = () => {
    const token = window.localStorage.getItem("token");
    if (token && player) {
      const url = "/profile";
      axios
        .get<IProfile>(url, {
          headers: {
            "x-access-token": token,
            "x-player-email": player,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const p = res.data as IProfile;
            if (p) {
              setProfile(p);
              setShowTutorial(p.showTutorial);
            }
          } else if (res.status === 401) {
            console.error(res.data.toString());
            logout();
          } else {
            console.error(res.data.toString());
            //logout();
          }
        }).catch(err => {
          console.error(err.toString());
          //logout();
        });
    } else {
      console.log("No token");
    }
  };

  const saveProfile = (profileSaveRequest: IProfileSaveRequest) => {
    const token = window.localStorage.getItem("token");
    if (token && player) {
      const url = "/profile/save";
      axios
        .post<IProfile>(url, profileSaveRequest, {
          headers: {
            "x-access-token": token,
            "x-player-email": player,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const p = res.data as IProfile;
            if (p) {
              setProfile(p);
              setShowTutorial(p.showTutorial);
              setShowProfile(false);
            }

          } else {
            console.error(res.data.toString());
            logout();
          }
        }).catch(err => {
          console.error(err.toString());
          logout();
        });
    } else {
      console.log("No token");
    }
  };

  const completeTutorial = () => {
    const updatedProfile = { showTutorial: false };
    saveProfile(updatedProfile);
  };

  useEffect(() => {
    if (gameId) {
      fetchTurn(gameId);
      fetchGameChat();
    }
    else
      setChat(undefined)
  }, [gameId]);

  useEffect(() => {
    fetchGameChat();
  }, [turn]);

  useEffect(() => {
    if (token) {
      fetchGames();
      getProfile();
    }
  }, [fetchGames, token]);

  useEffect(() => {
    if (goToNextFlag) {
      nextGame();
      setGoToNextFlag(false);
    }
  }, [lobby]);


  const refresh = () => {
    if (gameId) fetchTurn(gameId);
  };

  useInterval(() => {
    console.log("refreshing", turn?.isPlayersTurn);
    refresh();
  }, !turn?.isPlayersTurn ? 5000 : null
  );

  const listGames = () => {
    setGameId(undefined);
    setChat(undefined);
    fetchGames();
    setListGamesDialog(true);
    setNewGameDialog(false)
    setHowToPlay(false);
    setShowProfile(false);
    setGoToNextFlag(false);
  };

  const fetchGamesAndNextGame = () => {
    setGoToNextFlag(true);
    fetchGames();
  };

  const nextGame = () => {
    if (lobby)
      if (gameId) {
        // const isSessionMyTurn = lobby.games.filter((g) => g.gameId === gameId && g.player === g.waitingOn).length > 0;
        const myTurns = lobby.games.filter((g) => g.gameId !== gameId && (g.isTurn));
        const orderedTurns = _.orderBy(
          myTurns,
          ["round"],
          ["desc"]
        );
        const fst = _.first(orderedTurns);
        if (fst) {
          selectGame(fst.gameId);

          // if (myTurns.length > 0) {
          //   const myIndex = lobby.games.findIndex((g) => g.gameId === gameId);
          //   const nexti = (myIndex + 1) % lobby.games.length;
          //   for (let i = nexti; i < lobby.games.length; i++) {
          //     if (lobby.games[i].waitingOn === lobby.games[i].player) {
          //       selectGame(lobby.games[i].gameId);
          //       return;
          //     }
          //   }
          //   for (let i = 0; i < nexti; i++) {
          //     if (lobby.games[i].waitingOn === lobby.games[i].player) {
          //       selectGame(lobby.games[i].gameId);
          //       return;
          //     }
          //   }
        } else {
          setListGamesDialog(true);
          setNewGameDialog(false);
          setHowToPlay(false);
          setShowProfile(false);
          setTurn(undefined);
          setChat(undefined);
        }
      }
    // else {
    //   const myTurns = lobby.games.filter((g) => g.player === g.waitingOn);
    //   if (myTurns.length > 0)
    //     selectGame(myTurns[0].gameId);
    //   else if (lobby.games.length > 0)
    //     selectGame(lobby.games[0].gameId);
    // }
  };


  const newGame = () => {
    fetchFriends();
    setNewGameDialog(!newGameDialog)
    setListGamesDialog(false);
    setHowToPlay(false);
    setShowProfile(false)
    setChat(undefined);
  };

  const toggleHowToPlay = () => {
    setHowToPlay(!howToPlay)
    setListGamesDialog(false);
    setNewGameDialog(false)
    setShowProfile(false)
    setChat(undefined);
  };

  const toggleShowProfile = () => {
    getProfile();
    setShowProfile(!showProfile);
    setListGamesDialog(false);
    setNewGameDialog(false)
    setHowToPlay(false);
    setChat(undefined);
  };

  const selectGame = (gameId: string) => {
    window.localStorage.setItem("gameId", gameId);
    setGameId(gameId);
    setChat(undefined);
    setListGamesDialog(false);
    fetchTurn(gameId);
    setToggle(!toggle);
  };

  const addFriend = (friend: IFriend) => {
    const newList = friends?.friends.map((f: IFriend) => {
      return { ...f, isSelected: f.identity === friend.identity ? true : f.isSelected };
    });
    setFriends({ friends: _.compact(newList) });
  };

  const removeFriend = (friend: IFriend) => {
    const newList = friends?.friends.map((f: IFriend) => {
      return { ...f, isSelected: f.identity === friend.identity ? false : f.isSelected };
    });
    setFriends({ friends: _.compact(newList) });
  };

  const isAdmin = player?.toLowerCase() === "bryanconneely@gmail.com";

  return (
    <Grid container item direction={"row"} style={{ background: "#fafafa", width: "100%" }}>
      <Grid container item xs={3} style={{ background: "#707070", padding: "2px", width: "100%" }}>
        {token && (
          <Grid item>
            <IconButton
              style={{
                background: "lightgreen",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "4px",
              }}
              onClick={listGames}
            >
              <HomeIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <Grid container item xs={9}
        display="flex"
        justifyContent="end"

        style={{ background: "#707070", padding: "2px", width: "100%" }}>

        {/* {isAdmin && !showAdmin && (
          <Grid item>
            <IconButton
              style={{
                background: "ligthgray",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "4px",
              }}
              onClick={(e) => {
                setShowAdmin(!showAdmin);
              }}
            >
              <ArrowDownwardIcon />
            </IconButton>

          </Grid>
        )}
        {isAdmin && showAdmin && (
          <Grid item>
            <IconButton
              style={{
                background: "ligthgray",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "4px",
              }}
              onClick={(e) => {
                setShowAdmin(!showAdmin);
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>

          </Grid>
        )} */}
        {/* {token && (
          <Grid item>
            <IconButton
              style={{
                background: "wheat",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "4px",
              }}
              onClick={toggleHowToPlay}

            >
              <HelpOutlineIcon />
            </IconButton>
          </Grid>
        )}
        {token && (
          <Grid item>
            <IconButton
              style={{
                background: "wheat",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "4px",
              }}
              onClick={toggleShowProfile}

            >
              <PersonIcon />
            </IconButton>
          </Grid>
        )} */}

        {token && (
          <Grid item>
            <IconButton
              style={{
                background: "wheat",
                color: "black",
                width: "40px",
                height: "40px",
                border: "1px solid #0f1919",
                padding: "0px",
                margin: "4px",
              }}
              onClick={resetLocalStorage}
            >
              <LogoutIcon />
            </IconButton>

          </Grid>
        )}
      </Grid>

      {/* {showAdmin && isAdmin && (
        <Grid container item style={{ background: "#703030", padding: "2px", width: "100%" }}>

          <IconButton
            style={{
              background: "lightblue",
              color: "black",
              width: "40px",
              height: "40px",
              border: "1px solid #0f1919",
              padding: "0px",
              margin: "4px",
            }}
            onClick={resetGame}
          >
            <RestartAltIcon />
          </IconButton>

          <IconButton
            style={{
              background: "red",
              color: "black",
              width: "40px",
              height: "40px",
              border: "1px solid #0f1919",
              padding: "0px",
              margin: "4px",
            }}
            onClick={deleteGame}
          >
            <CancelIcon />
          </IconButton>

        </Grid>
      )} */}

      {!token && (
        <Grid item xs={12}>
          <SignIn login={login} />
        </Grid>
      )}

      {token && player && lobby && !showTutorial && listGamesDialog && !newGameDialog && (
        <Grid item xs={12}>
          <Lobby lobby={lobby} createGame={newGame} selectGame={selectGame} refresh={fetchGames} />
        </Grid>
      )}

      {howToPlay && (
        <Grid item xs={12}>
          <HowToPlay />
        </Grid>
      )}

      {token && player && newGameDialog && (
        <Grid item xs={12}>
          <NewGame
            friends={friends || { friends: [] }}
            createGame={createGame}
            addFriend={addFriend}
            removeFriend={removeFriend}
          />
        </Grid>
      )}

      {token && showProfile && profile && !listGamesDialog && !newGameDialog && !howToPlay && (
        <Grid item xs={12}>
          <Profile
            profile={profile}
            saveProfile={saveProfile}
          />
        </Grid>
      )}

      {token && showTutorial && !howToPlay && !showProfile && (
        <Grid item xs={12} style={{ margin: "4px", border: "1px solid #0f1919", borderRadius: "4px" }}>
          <Tutorial
            playerName={profile?.name || ''}
            completeTutorial={completeTutorial}
            saveProfile={saveProfile}
          />
        </Grid>
      )}

      {
        token && !showTutorial && player && turn && !listGamesDialog && !newGameDialog && !howToPlay && gameId && !showProfile && (
          <Grid item xs={12}>
            <Shell
              token={token}
              gameId={gameId}
              playerEmail={player || ''}
              turn={turn}
              chat={chat}
              profile={profile}
              reset={toggle}
              fetchGames={fetchGames}
              refreshGame={refreshGame}
              hardRefreshGame={hardRefreshGame}
              nextGame={fetchGamesAndNextGame}
              logout={logout}
              sendChat={sendChat}
            />
          </Grid>
        )}

      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        style={{
          background: "transparent",
          border: "0px solid #0f1919",
          marginTop: "20px",
          marginBottom: "4px",
        }}
      >
        <Typography
          align="center"
          style={{
            color: "grey",
            fontSize: "10px",
            width: "100%",
            fontWeight: "550",
          }}
        >
          Disc &#174;  2024 &#169;
        </Typography>


      </Grid>
    </Grid >
  );
};

export default Disc;
