import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { ILobbyGame, ILobbyGamePlayerSummary } from "../../Types/types";
import _ from "lodash";
type LobbyGameProps = {
  game: ILobbyGame;
  selectGame: (session: string) => void;
};

const LobbyGame: React.FC<LobbyGameProps> = ({
  game,
  selectGame,
}: LobbyGameProps) => {



  // const summ = game.totals.find((x) => x.player === game.player);
  // const isTurn = summ ? summ.isTurn : false;
  // const canHaveRoundHighest = game.totals.filter((x) => x.roundTotal !== 0).length > 0;
  // const canHaveGameighest = game.totals.filter((x) => x.gameTotal !== 0).length > 0;
  // const hand = _.first(game.totals.filter((x) => !x.isOut && x.hand.length > 0));
  return (
    <Button
      style={{
        //background: game.waitingOnMe ? "#e0e0e0" : "lightyellow",
        // boxShadow: xxx ? '0 0 16px gold' : '0 0 0px #171C21',
        background: "transparent",
        width: "350px",
        border: "1px solid #001d3d",
        padding: "0px",
        margin: "0px",
        minWidth: 0,
        minHeight: 0,
      }}
      onClick={(e) => {
        selectGame(game.gameId);
      }}
    >
      <Grid
        container
        item xs={12}
        style={{
          textTransform: "none",
        }}
      >

        <Grid
          container
          item
          xs={12}
          style={{
            background: game.isTurn ? "#aad576" : "#ade8f4",
            // width: "170px",
            margin: "0px",
            padding: "0px",
            border: "0px solid #0f1919",
            textTransform: "none",
          }}
        >

          <Grid container item xs={12} justifyContent="center" >
            {game.stacks.map((res: ILobbyGamePlayerSummary, i) => (
              <Grid
                container
                item
                xs={4}
                key={"k-gstss" + i}
                display={"flex"}
                justifyContent={"center"}
                direction={"row"}
                style={{
                  width: "100%",
                  padding: "0px",
                  marginTop: "4px",
                  marginBottom: "0px",
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={"center"}
                  style={{
                    width: "100%",
                    height: "46px",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent={"center"}
                    direction={"row"}
                    style={{
                      border: "0px solid #0f1919",
                      marginBottom: "4px",
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent={"center"}
                      style={{
                        border: "0px solid #0f1919",
                        marginTop: "10px",
                      }}
                    >
                      <Grid
                        container
                        item xs={6}
                        justifyContent={"center"}
                        style={{
                          border: "0px solid #0f1919",
                          marginTop: "0px",
                        }}
                      >
                        <Typography
                          variant="h1"
                          style={{
                            color: game.isTurn ? "#245501" : "#023e8a",
                            fontWeight: 450,
                            // marginLeft: "4px",
                            // marginTop: "4px",
                          }}
                        >
                          {res.player}
                        </Typography>
                      </Grid>

                      <Grid
                        container
                        item xs={4}
                        justifyContent={"center"}
                        style={{
                          border: "0px solid #0f1919",
                          marginTop: "0px",
                        }}
                      >
                        <Typography
                          variant="h1"
                          style={{
                            color: game.isTurn ? "#245501" : "#023e8a",
                            fontWeight: 450,
                            // marginLeft: "4px",
                            // marginTop: "4px",
                          }}
                        >
                          {res.stack}
                        </Typography>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          style={{
            background: "transparent",
            margin: "0px",
            padding: "0px",
            border: "0px solid #0f1919",
          }}
        >
        </Grid>

      </Grid>
    </Button >
  );
};

export default LobbyGame;
